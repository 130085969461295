import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/nextjs"
import * as api from "./onboardingBrokerageAPI"
import { OnboardingQuizState } from "./types"

export const fetchWealthApplication = createAsyncThunk(
  "onboardingBrokerage/fetchWealthApplication",
  api.getWealthApplication
)
export const fetchBrokerageAccount = createAsyncThunk(
  "onboardingBrokerage/fetchBrokerageAccount",
  api.getWealthBrokerageAccount
)
export const fetchWealthAddress = createAsyncThunk("onboardingBrokerage/fetchWealthAddress", api.getWealthAddress)
export const fetchWealthUser = createAsyncThunk("onboardingBrokerage/fetchWealthUser", api.getWealthUser)

export const saveWealthApplication = createAsyncThunk(
  "onboardingQuiz/persistWealthApplication",
  api.saveWealthApplication
)
export const saveWealthUser = createAsyncThunk("onboardingQuiz/persistWealthUser", api.saveWealthUser)
export const saveWealthAddress = createAsyncThunk("onboardingQuiz/persistWealthAddress", api.saveWealthAddress)

const initialState: OnboardingQuizState = {
  wealthUser: null,
  wealthApplication: null,
  wealthBrokerageAccount: null,
  address: null,
  isLoading: false,
  isIrsFlowShown: false,
  akoyaRedirectUrl: null,
}

export const onboardingBrokerageSlice = createSlice({
  name: "onboardingBrokerage",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setIsIrsFlowShown: (state, action: PayloadAction<boolean>) => {
      state.isIrsFlowShown = action.payload
    },
    setAkoyaRedirectUrl: (state, action: PayloadAction<string>) => {
      state.akoyaRedirectUrl = action.payload
    },
    setGivenName: (state, action: PayloadAction<string>) => {
      state.wealthUser.given_name = action.payload
    },
    setFamilyName: (state, action: PayloadAction<string>) => {
      state.wealthUser.family_name = action.payload
    },
    setPhoneNumber: (state, action: PayloadAction<string>) => {
      state.wealthUser.phone_number = action.payload
    },
    setSocialSecurityKey: (state, action: PayloadAction<string>) => {
      state.wealthUser.social_security_key = action.payload
    },
    setForeignIdNumber: (state, action: PayloadAction<string>) => {
      state.wealthUser.foreign_identification_number = action.payload
    },
    setDateOfBirth: (state, action: PayloadAction<string>) => {
      state.wealthUser.date_of_birth = action.payload
    },
    setCitizenshipCountry: (state, action: PayloadAction<string>) => {
      state.wealthUser.citizenship_country = action.payload
      if (action.payload === "USA") {
        state.wealthApplication.is_permanent_resident = true
      }
    },
    setResidencyCountry: (state, action: PayloadAction<string>) => {
      state.wealthUser.residency_country = action.payload
      state.address.country = action.payload
    },
    setApexDocumentReferenceId: (state, action: PayloadAction<string>) => {
      state.wealthUser.apex_document_reference_id = action.payload
    },
    setApexDocumentType: (state, action: PayloadAction<string>) => {
      state.wealthUser.apex_document_type = action.payload
    },
    setApexDocumentUploadedAt: (state, action: PayloadAction<string>) => {
      state.wealthUser.apex_document_uploaded_at = action.payload
    },
    setAddressLine1: (state, action: PayloadAction<string>) => {
      state.address.address_line1 = action.payload
    },
    setAddressLine2: (state, action: PayloadAction<string>) => {
      state.address.address_line2 = action.payload
    },
    setCity: (state, action: PayloadAction<string>) => {
      state.address.city = action.payload
    },
    setState: (state, action: PayloadAction<string>) => {
      state.address.state = action.payload
    },
    setPostalCode: (state, action: PayloadAction<string>) => {
      state.address.postal_code = action.payload
    },
    setIsPermanentResident: (state, action: PayloadAction<boolean>) => {
      state.wealthApplication.is_permanent_resident = action.payload
    },
    setBirthCountry: (state, action: PayloadAction<string>) => {
      state.wealthApplication.birth_country = action.payload
    },
    setVisaType: (state, action: PayloadAction<string>) => {
      state.wealthApplication.visa_type = action.payload
    },
    setVisaExpirationDate: (state, action: PayloadAction<string>) => {
      state.wealthApplication.visa_expiration_date = action.payload
    },
    setAccountNumberKey: (state, action: PayloadAction<number | string>) => {
      state.wealthApplication.account_number_key = action.payload
    },
    setSignatureSVG: (state, action: PayloadAction<string | null>) => {
      state.wealthApplication.signature_svg = action.payload
    },
    setCipInvestigationComplete: (state, action: PayloadAction<boolean>) => {
      state.wealthApplication.cip_investigation_complete = action.payload
      api.saveWealthApplication(state.wealthApplication)
    },
    setIrsFlowStep: (state, action: PayloadAction<number>) => {
      state.wealthApplication.irs_flow_step = action.payload
      state.address.country = state.wealthUser.residency_country
      api.saveWealthApplication(state.wealthApplication)
      api.saveWealthUser(state.wealthUser)
      api.saveWealthAddress(state.address)
    },
    setIrsFlowInnerStep: (state, action: PayloadAction<number>) => {
      state.wealthApplication.irs_flow_inner_step = action.payload
      state.address.country = state.wealthUser.residency_country
      api.saveWealthApplication(state.wealthApplication)
      api.saveWealthUser(state.wealthUser)
      api.saveWealthAddress(state.address)
    },
    setLoadingBrokerageAccount: (state, action: PayloadAction<string>) => {
      state.wealthBrokerageAccount = {
        ...state.wealthBrokerageAccount,
        status: action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWealthApplication.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchWealthApplication.fulfilled, (state, action) => {
        const { payload } = action
        if (payload) {
          state.wealthApplication = payload
        }
        state.isLoading = false
        return state
      })
      .addCase(fetchWealthApplication.rejected, (_, action) => {
        Sentry.captureException(`Failed to fetch wealth application: ${action.error}`)
      })
      .addCase(fetchBrokerageAccount.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchBrokerageAccount.fulfilled, (state, action) => {
        const { payload } = action
        if (payload) {
          state.wealthBrokerageAccount = payload.brokerageAccount
        }
        state.isLoading = false
        return state
      })
      .addCase(fetchBrokerageAccount.rejected, (_, action) => {
        Sentry.captureException(`Failed to fetch wealth brokerage account: ${action.error}`)
      })
      .addCase(fetchWealthAddress.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchWealthAddress.fulfilled, (state, action) => {
        const { payload } = action
        if (payload) {
          state.address = payload
        }
        state.isLoading = false
        return state
      })
      .addCase(fetchWealthAddress.rejected, (_, action) => {
        Sentry.captureException(`Failed to fetch wealth address: ${action.error}`)
      })
      .addCase(fetchWealthUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchWealthUser.fulfilled, (state, action) => {
        const { payload } = action
        if (payload) {
          state.wealthUser = payload
        }
        state.isLoading = false
        return state
      })
      .addCase(fetchWealthUser.rejected, (_, action) => {
        Sentry.captureException(`Failed to fetch wealth user: ${action.error}`)
      })
  },
})

export const {
  setIsLoading,
  setIsIrsFlowShown,
  setAkoyaRedirectUrl,
  setGivenName,
  setFamilyName,
  setPhoneNumber,
  setSocialSecurityKey,
  setForeignIdNumber,
  setDateOfBirth,
  setCitizenshipCountry,
  setResidencyCountry,
  setAddressLine1,
  setAddressLine2,
  setCity,
  setState,
  setPostalCode,
  setIsPermanentResident,
  setBirthCountry,
  setVisaType,
  setVisaExpirationDate,
  setAccountNumberKey,
  setSignatureSVG,
  setCipInvestigationComplete,
  setIrsFlowStep,
  setIrsFlowInnerStep,
  setLoadingBrokerageAccount,
  setApexDocumentReferenceId,
  setApexDocumentType,
  setApexDocumentUploadedAt,
} = onboardingBrokerageSlice.actions

export default onboardingBrokerageSlice.reducer
