import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/nextjs"
import * as api from "./onboardingRecommendationsAPI"
import { OnboardingRecommendationsState } from "./types"

const initialState: OnboardingRecommendationsState = {
  recommendations: undefined,
  monteCarloSimulation: undefined,
  isLoading: false,
  strategy: {
    seasoningPeriod: 30,
  },
}

export const fetchRecommendations = createAsyncThunk("onboardingRecommendations/fetch", api.fetchRecommendations)

export const setSeasoningPeriod = createAsyncThunk(
  "onboardingRecommendations/setSeasoningPeriod",
  async (period: number, { dispatch, getState }) => {
    dispatch(onboardingRecommendationsSlice.actions.setSeasoningPeriod(period))
  }
)

export const onboardingRecommendationsSlice = createSlice({
  name: "onboardingRecommendations",
  initialState,
  reducers: {
    setPercentageToSell: (state, action: PayloadAction<number>) => {
      state.strategy.percentageToSell = action.payload
    },
    setFutureRsusOnly: (state, action: PayloadAction<boolean>) => {
      state.strategy.futureRsusOnly = action.payload
    },
    setSeasoningPeriod: (state, action: PayloadAction<number>) => {
      state.strategy.seasoningPeriod = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecommendations.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchRecommendations.fulfilled, (state, action) => {
        const { payload } = action
        if (payload) {
          state.recommendations = payload.recommendations
          state.monteCarloSimulation = payload.monteCarloSimulation
        }
        state.isLoading = false
        if (!state.strategy.percentageToSell && state.strategy.percentageToSell !== 0) {
          state.strategy.percentageToSell = payload.recommendations.recommendedPercentageToSell
          state.strategy.percentageToSellFuture = payload.recommendations.recommendedPercentageToSellFuture
          state.strategy.recommendedNetWorthPercentage = payload.recommendations.recommendedNetWorthPercentage
        }
        if (!state.strategy.futureRsusOnly) {
          state.strategy.futureRsusOnly = payload.recommendations.recommendFutureOnly
        }
        if (payload.recommendations.priceLimit?.priceTarget) {
          state.strategy.priceTarget = payload.recommendations.priceLimit.priceTarget
          state.strategy.selloffTradeCount = 20
          state.strategy.selloffSpacingDays = 1
        } else {
          state.strategy.priceTarget = undefined
          state.strategy.selloffTradeCount = 12
          state.strategy.selloffSpacingDays = 7
        }
        if (payload.recommendations.recommend10b51Plan === false) {
          state.strategy.seasoningPeriod = 0
        }
        state.strategy.is10b51 = payload.recommendations.recommend10b51Plan
        state.strategy.washSale = payload.recommendations.recommendWashSale
        return state
      })
      .addCase(fetchRecommendations.rejected, (_, action) => {
        Sentry.captureException("Failed to fetch onboarding recommendations", {
          extra: {
            error: action.error,
          },
        })
      })
  },
})

export default onboardingRecommendationsSlice.reducer
