var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2faaa656bdb35844be96d6fd667a3e9b293cbfe8"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"

const includesWealthKeyword = (string) =>
  typeof string === "string" &&
  (string.includes("dashboard") ||
    string.includes("admin") ||
    string.includes("sofia") ||
    string.includes("start") ||
    string.includes("onboarding") ||
    string.includes("issuer"))

Sentry.init({
  dsn: process.env.SENTRY_CLIENT_DSN,
  enabled:
    process.env.NODE_ENV === "production" &&
    !process.env.IS_PREVIEW &&
    process.env.NEXT_PUBLIC_APP_ENV !== "integration",
  environment: process.env.IS_PREVIEW ? "preview" : process.env.NODE_ENV,
  tunnel: "/api/exception", // proxy to avoid adblockers
  tracesSampleRate: 1.0,
  beforeSend(event) {
    // Flag wealth-related events with a higher priority tag
    const requestURL = event.request?.url || ""

    if (
      includesWealthKeyword(requestURL) ||
      event.exception?.values?.some((d) => includesWealthKeyword(d.value.includes))
    ) {
      if (event.tags) {
        event.tags["wealth"] = "true"
      }
    }
    return event
  },
})
