// import { QuizState } from "./types"
import amplitude from "amplitude-js"
import { toast } from "react-toastify"

import { WealthAccounts } from "candor-common"
import axios from "axios"
import { YodleeLinkPostBody, YodleeMfaOptionSelectBody, YodleeMfaPutBody } from "pages/api/yodlee/link"
import { showErrorMessageAndLogToSentry } from "helpers/frontendError"
import analytics from "helpers/analytics"
import { User } from "helpers/userHelper"
import { BrokerageLinkBody } from "pages/api/brokerage/link"

export const getWealthApplication = async (): Promise<any | null> => {
  const response = await fetch(`/api/onboarding/application`, {
    method: "GET",
  })
  const { wealthApplication } = await response.json()

  return wealthApplication || null
}

export const getWealthBrokerageAccount = async (): Promise<{ brokerageAccount: WealthAccounts } | null> => {
  const response = await fetch(`/api/onboarding/brokerage`, {
    method: "GET",
  })
  if (!response.ok) {
    throw new Error("Failed to fetch brokerage account")
  }
  const brokerageAccounts = await response.json()
  return brokerageAccounts || []
}

export const saveWealthApplication = async (wealthApplication): Promise<any | null> => {
  const response = await fetch(`/api/onboarding/application`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ wealthApplication }),
  })
  await response.json()
}

export const getWealthAddress = async (): Promise<any | null> => {
  const response = await fetch(`/api/onboarding/address`, {
    method: "GET",
  })
  const { address } = await response.json()

  return address || null
}

export const saveWealthAddress = async (address): Promise<any | null> => {
  const response = await fetch(`/api/onboarding/address`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ address }),
  })
  await response.json()
}

export const getWealthUser = async (): Promise<User | null> => {
  const response = await fetch(`/api/onboarding/user`, {
    method: "GET",
  })
  const { user } = await response.json()

  return user || null
}

export const saveWealthUser = async (wealthUser): Promise<any | null> => {
  await fetch(`/api/onboarding/user`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ wealthUser }),
  })
}

export const getPlaidToken = async ({ brokerage }) => {
  // Fetch token from Plaid
  const response = await fetch("/api/plaid/brokerage/link", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      brokerage,
      redirect_uri: window.location.href,
    }),
  })
  if (!response.ok) {
    const resp = await response.json()
    if (resp.reason) {
      return showErrorMessageAndLogToSentry({
        error: resp?.reason,
        details: { resp },
      })
    } else {
      return showErrorMessageAndLogToSentry({
        error: "An unknown error occurred",
      })
    }
  }
  const token = await response.json()

  return token?.link_token
}

export const getYodleePublicKey = async () => {
  const response = await fetch("/api/yodlee/link", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
  if (!response.ok) {
    showErrorMessageAndLogToSentry({
      message: "Internal error",
    })
    return undefined
  }
  return await response.json()
}

export const saveYodleeCredentials = async (body: YodleeLinkPostBody) => {
  const response = await fetch("/api/yodlee/link", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  })
  if (!response.ok) {
    const body = await response.json()
    if (body.error === "LOGIN_FAIL") {
      return toast.error("Login failed. Please check your credentials and try again.")
    }
    return showErrorMessageAndLogToSentry({
      message: "Internal error",
    })
  }
  return await response.json()
}

export const initiateBrokerageLink = async (body: BrokerageLinkBody) => {
  try {
    const response = await axios.post("/api/brokerage/link", body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    return response.data
  } catch (error) {
    return showErrorMessageAndLogToSentry({
      message: "Internal error",
    })
  }
}

export const selectYodleeMfaType = async (body: YodleeMfaOptionSelectBody) => {
  const response = await fetch("/api/yodlee/link", {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  })
  if (!response.ok) {
    const body = await response.json()
    if (body.error === "LOGIN_FAIL") {
      return toast.error("Login failed. Please check your credentials and try again.")
    }
    return showErrorMessageAndLogToSentry({
      message: "Internal error",
    })
  }
  return await response.json()
}

export const saveYodleeMfa = async (body: YodleeMfaPutBody) => {
  const response = await fetch("/api/yodlee/link", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  })
  if (!response.ok) {
    const body = await response.json()
    if (body.error === "LOGIN_FAIL") {
      return toast.error("Login failed. Please check your credentials and try again.")
    }
    return showErrorMessageAndLogToSentry({
      message: "Internal error",
    })
  }
  return await response.json()
}

export const persistApplication = async ({ public_token, user, company }) => {
  const payload: Record<string, string> = {
    public_token,
    company,
  }
  if (amplitude && process.env.AMPLITUDE_API_KEY) {
    payload.amplitude_device_id = amplitude.getInstance().options.deviceId
  }

  const response = await fetch("/api/plaid/brokerage/save", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(payload),
  })
  if (response.ok) {
    const responseDetails = await response.json()

    if (user) {
      // Add info to Clerk profile if available
      if (responseDetails.identity.phone_number) {
        user.createPhoneNumber({ phoneNumber: responseDetails.identity.phone_number })
      }
      if (responseDetails.identity.given_name && responseDetails.identity.family_name) {
        user.update({
          firstName: responseDetails.identity.given_name,
          lastName: responseDetails.identity.family_name,
        })
      }
    }

    analytics.track("Onboarding connect brokerage completed", {
      user: { company, brokerage: responseDetails.institution, phoneNumber: responseDetails.identity.phone_number },
    })
  } else {
    const errorResponse = await response.json()

    showErrorMessageAndLogToSentry({
      message: "Could not connect to your brokerage at this time",
      details: { company },
      error: errorResponse?.reason,
    })
  }
}

export const persistBrokerageAccount = async ({ account, wealthApplicationId, institution }) => {
  if (!account) return

  // Track event
  analytics.track("Onboarding connect brokerage - equity account selected")

  await fetch("/api/onboarding/patch-application", {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      wealthApplicationId: wealthApplicationId,
      equityAccountId: account?.account_id,
      equityAccountMask: account.mask,
      institution,
    }),
  })
}

export const persistEmptyApplication = async ({ company_id, institution_id }) => {
  await fetch("/api/onboarding/optional-application", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      company_id,
      institution_id,
    }),
  })
}
